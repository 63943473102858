<template>
  <div class="about-us">
    <div class="title">
      <c-title :width="16">
        <div class="text">{{$t('aboutTitle')}}</div>
      </c-title>
    </div>
    <div v-html="info.articleDetails" class="content"></div>
  </div>
</template>

<script>
import { aboutUsInfo } from '@/api/about'
import cTitle from '@/components/cTitle/cTitle.vue'


export default {
  name: 'aboutUs',
  components: {
    cTitle
  },
  data() {
    return {
      info: {}
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      aboutUsInfo().then((res) => {
        if (res.code === 10000) {
          // console.log(res.data)
          res.data.articleDetails = res.data.articleDetails.replace(
            /<img/g,
            '<img style="max-width:100%;height: auto;"'
          )
          this.info = res.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
.about-us {
  width: 1140px;
  min-height: 500px;
  margin: 41px auto 0;
  background: url('../../static/img/about/bg.png') no-repeat center;
  background-size: cover;
  padding: 0 0 30px 0;
  .title {
    font-size: 28px;
    line-height: 28px;
    text-align: center;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 88px 0 47px 0;
  }
  .content {
    padding: 0 100px 80px;
  }
}
</style>